import { BloquearLeads } from "containers/BloquearLeads/BloquearLeads";
import Bloques from "containers/Bloques/Bloques";
import EditarTops from "containers/EditarTops/EditarTops";
import EncuestaResultados from "containers/Encuestas/EncuestaResultados";
import VistaTopEncuesta from "containers/Encuestas/VistaTopEncuestas";
import Error from "containers/ErrorCustom";
import { KPIJosep } from "containers/KPIJosep/KPIJosep";
import KpiRepsolBajasAg from "containers/KpiRepsolBajas/KpiRepsolBajasAg";
import Proximos50Leads from "containers/ProximosLeads/ProximosLeads";
import { TopsEnLinea } from "containers/TopsEnLinea/TopsEnLinea";
import Ultimos50Leads from "containers/UltimosLeads/UltimosLeads";
import React, { lazy, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { setUsuarioEstado } from "socket/functions/setUsuarioEstado";
import GlobalLayout from "../components/GlobalLayout/GlobalLayout";
import Guard from "./Guard";

import LookerStudio from "containers/LookerStudio/Report";
import BloquesPriorodad from "containers/BloquesPrioridad/BloquesPrioridad";
import { notification } from "antd";
import MargenesNew from "containers/Margenes/MargenesNew";
import MargenesCambiados from "containers/Margenes/Margenes_Cambiados";
import TopsHabilitar from "containers/TopsHabilitar/TopsHabilitar";
const Tiempos = lazy(() => import("../containers/Tiempos/Tiempos"));

const Login = lazy(() => import("../containers/Login/Login"));
const Home = lazy(() => import("../containers/Home/Home"));
const Estados = lazy(() => import("../containers/Estados/Estados"));
const Tops = lazy(() => import("../containers/Tops/Tops"));
const Subestados = lazy(() => import("../containers/Subestados/Subestados"));
const Llamada = lazy(() => import("../containers/Llamada"));
const Campanas = lazy(() => import("../containers/Campanas/Campanas"));
const NuevaCampana = lazy(() =>
  import("../containers/NuevaCampana/NuevaCampana")
);
const DetalleReglasEstados = lazy(() =>
  import("../containers/DetalleReglasEstados/DetalleReglasEstados")
);
const ResumenPorUsuario = lazy(() =>
  import("containers/Encuestas/ResumenPorUsuario")
);
const DetalleTop = lazy(() => import("../containers/DetalleTop/DetalleTop"));
const Caracteristicas = lazy(() =>
  import("../containers/Caracteristicas/Caracteristicas")
);
const CaracteristicasTops = lazy(() =>
  import("../containers/CaracteristicasTops/CaracteristicasTops")
);
const Reglas = lazy(() => import("../containers/Reglas/Reglas"));
const Leads = lazy(() => import("../containers/Leads/Leads"));

const ReclamacionesCerradas = lazy(() =>
  import("../containers/Reclamaciones/ReclamacionesCerradas")
);
const ReclamacionesPendientes = lazy(() =>
  import("../containers/Reclamaciones/ReclamacionesPendientes")
);
const Dashboard = lazy(() => import("../containers/Dashboard/Dashboard"));
const Encuestas = lazy(() => import("../containers/Encuestas/Encuestas"));
const NewEncuesta = lazy(() =>
  import("../containers/Encuestas/EditorEncuesta")
);

const Indicadores = lazy(() => import("../containers/Indicadores/Indicadores"));
const Totales = lazy(() => import("../containers/Totales/Totales"));
const Agentes = lazy(() => import("../containers/Agentes/Agentes"));
const AgentesCampanas = lazy(() =>
  import("../containers/AgentesCampanas/AgentesCampanas")
);
const Incentivos = lazy(() => import("../containers/Incentivos/Incentivos"));
const Mailchimp = lazy(() => import("../containers/Mailchimp/Mailchimp"));
const MailchimpView = lazy(() =>
  import("../containers/Mailchimp/MailchimpView")
);
const TraspasoLeadsAplazados = lazy(() =>
  import("../containers/TraspasoLeadsAplazados/TraspasoLeadsAplazados")
);
const CreacionRapidaLead = lazy(() =>
  import("../containers/CreacionRapidaLead/CreacionRapidaLead")
);
const Sala = lazy(() => import("containers/Sala"));
const ActividadTop = lazy(() =>
  import("../containers/ActividadTop/ActividadTop")
);
const LlamadaEntrante = lazy(() =>
  import("../containers/LlamadaEntrante/LlamadaEntrante")
);
const LineaTemporalLeads = lazy(() =>
  import("../containers/LineaTemporalLeads/LineaTemporalLeads")
);
const Tickets = lazy(() => import("../containers/Tickets/Tickets"));
const Estadisticas = lazy(() =>
  import("../containers/Estadisticas/Estadisticas")
);
const Estadisticas2 = lazy(() =>
  import("../containers/Estadisticas/Estadisticas2")
);
const Timeline = lazy(() => import("../containers/Timeline/Timeline"));
const Calendario = lazy(() => import("../containers/Calendario"));
const LeadsEnUso = lazy(() => import("../containers/LeadsEnUso"));
const Margenes = lazy(() => import("../containers/Margenes/Margenes"));
const CalculadoraRetencion = lazy(() =>
  import("../containers/CalculadoraRetencion/CalculadoraRetencion")
);
const LeadsPorHora = lazy(() => import("../containers/LeadsPorHora"));
const Comercializadoras = lazy(() =>
  import("../containers/Comercializadoras/Comercializadoras")
);
const TarifasComercializadoras = lazy(() =>
  import("../containers/Comercializadoras/TarifasComercializadoras")
);
const DetalleTarifaComercializadora = lazy(() =>
  import("../containers/Comercializadoras/DetalleTarifaComercializadora")
);
const DesgloseLeads = lazy(() => import("../containers/DesgloseLeads"));
const VentajasAhorradas = lazy(() =>
  import("../containers/Descuentos/VentajasAhorradas")
);
const FunnelCaidas = lazy(() => import("../containers/Campanas/FunnelCaidas"));
const SeguimientoDiarioKpi = lazy(() =>
  import("../containers/Campanas/SeguimientoDiarioKpi")
);
const ConteoBajas = lazy(() => import("../containers/ConteoBajas"));
const CondicionesTraspaso = lazy(() =>
  import("../containers/CondicionesTraspaso/CondicionesTraspaso")
);
const ListaRetenciones = lazy(() => import("../containers/ListaRetenciones"));
const CUPSColaboradores = lazy(() =>
  import("../containers/CUPSColaboradores/CUPSColaboradores")
);
const Traducciones = lazy(() =>
  import("../containers/Traducciones/Traducciones")
);

const BuscadorTelefonos = lazy(() => import("../containers/BuscadorTelefonos/BuscadorTelefonos"));

const KpiRepsol = lazy(() => import("../containers/KpiRepsol/KpiRepsol"));

const KpiRepsolAg = lazy(() => import("../containers/KpiRepsol/KpiRepsolAg"));

export default function Routes() {
  const location = useLocation();

  useEffect(() => {
    notification.destroy();
  }, [location]);

  useEffect(() => {
    setUsuarioEstado({
      nombre: "navegando",
      url: location.pathname,
      query: location.search,
    });
  });

  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>

      <Route path="/estados" exact>
        <GlobalLayout>
          <Guard component={<Estados />} />
        </GlobalLayout>
      </Route>

      <Route path="/tops" exact>
        <GlobalLayout>
          <Guard component={<Tops />} />
        </GlobalLayout>
      </Route>

      <Route path="/actividadTop" exact>
        <GlobalLayout>
          <Guard component={<ActividadTop />} />
        </GlobalLayout>
      </Route>

      <Route path="/subestados" exact>
        <GlobalLayout>
          <Guard component={<Subestados />} />
        </GlobalLayout>
      </Route>

      <Route path="/campanas" exact>
        <GlobalLayout>
          <Guard component={<Campanas />} />
        </GlobalLayout>
      </Route>
      <Route path="/bloquesPrioridad" exact>
        <GlobalLayout>
          <Guard component={<BloquesPriorodad />} />
        </GlobalLayout>
      </Route>
      <Route path="/bloques" exact>
        <GlobalLayout>
          <Guard component={<Bloques />} />
        </GlobalLayout>
      </Route>

      <Route path="/editarTops" exact>
        <GlobalLayout>
          <Guard component={<EditarTops />} />
        </GlobalLayout>
      </Route>

      <Route path="/caracteristicasCampana" exact>
        <GlobalLayout>
          <Guard component={<Caracteristicas />} />
        </GlobalLayout>
      </Route>

      <Route path="/leads" exact>
        <GlobalLayout>
          <Guard component={<Leads />} />
        </GlobalLayout>
      </Route>

      <Route path="/buscadorTelefonos" exact>
        <GlobalLayout>
          <Guard component={<BuscadorTelefonos />} />
        </GlobalLayout>
      </Route>

      <Route path="/CaracteristicasTops" exact>
        <GlobalLayout>
          <Guard component={<CaracteristicasTops />} />
        </GlobalLayout>
      </Route>

      <Route path="/detalleCampana" exact>
        <GlobalLayout>
          <Guard component={<NuevaCampana />} />
        </GlobalLayout>
      </Route>

      <Route path="/reglas" exact>
        <GlobalLayout>
          <Guard component={<Reglas filterCampanaOrigen="none" />} />
        </GlobalLayout>
      </Route>

      <Route path="/detalleReglaCampana" exact>
        <GlobalLayout>
          <Guard component={<DetalleReglasEstados />} />
        </GlobalLayout>
      </Route>

      <Route path="/detalleTop" exact>
        <GlobalLayout>
          <Guard component={<DetalleTop />} />
        </GlobalLayout>
      </Route>

      <Route path="/incentivos" exact>
        <GlobalLayout>
          <Guard
            component={<Incentivos />}
            allowedUsers={[316, 2, 3, 152, 38, 52, 399, 746, 1449, 292, 242, 18]}
          />{" "}
          {/* Jorge, Ricardo, Antonio , Manu, Fran , Jose, David, Josep, David U, Guillermo  */}
        </GlobalLayout>
      </Route>

      <Route path="/mailchimp" exact>
        <GlobalLayout>
          <Guard component={<Mailchimp />} />
        </GlobalLayout>
      </Route>

      <Route path="/mailchimpView" exact>
        <GlobalLayout>
          <Guard component={<MailchimpView />} />
        </GlobalLayout>
      </Route>

      <Route path="/" exact>
        <GlobalLayout>
          <Guard component={<Home />} />
        </GlobalLayout>
      </Route>

      <Route path="/llamada" exact>
        <GlobalLayout>
          <Guard component={<Llamada />} />
        </GlobalLayout>
      </Route>

      <Route path="/llamadaEntrante" exact>
        <GlobalLayout>
          <Guard component={<LlamadaEntrante />} />
        </GlobalLayout>
      </Route>

      <Route path="/retrasos" exact>
        <GlobalLayout>
          <Guard component={<Indicadores />} />
        </GlobalLayout>
      </Route>

      <Route path="/totales" exact>
        <GlobalLayout>
          <Guard component={<Totales />} />
        </GlobalLayout>
      </Route>

      <Route path="/agentes" exact>
        <GlobalLayout>
          <Guard component={<Agentes />} />
        </GlobalLayout>
      </Route>

      <Route path="/agentescampanas" exact>
        <GlobalLayout>
          <Guard component={<AgentesCampanas />} />
        </GlobalLayout>
      </Route>

      <Route path="/traspasoLeadsAplazados" exact>
        <GlobalLayout>
          <Guard component={<TraspasoLeadsAplazados />} />
        </GlobalLayout>
      </Route>

      <Route path="/creacionRapidaLead" exact>
        <GlobalLayout>
          <Guard component={<CreacionRapidaLead />} />
        </GlobalLayout>
      </Route>

      <Route path="/sala" exact>
        <GlobalLayout>
          <Guard component={<Sala />} />
        </GlobalLayout>
      </Route>
      <Route path="/leadsEnUso" exact>
        <GlobalLayout>
          <Guard component={<LeadsEnUso />} />
        </GlobalLayout>
      </Route>

      <Route path="/error" exact>
        <Guard component={<Error />} />
      </Route>

      <Route path="/proximosLeads" exact>
        <GlobalLayout>
          <Guard component={<Proximos50Leads />} />
        </GlobalLayout>
      </Route>

      <Route path="/lineaTemporalLeads" exact>
        <GlobalLayout>
          <Guard component={<LineaTemporalLeads />} />
        </GlobalLayout>
      </Route>

      <Route path="/ultimosLeads" exact>
        <GlobalLayout>
          <Guard component={<Ultimos50Leads />} />
        </GlobalLayout>
      </Route>

      <Route path="/tickets" exact>
        <GlobalLayout>
          <Guard component={<Tickets />} />
        </GlobalLayout>
      </Route>

      <Route path="/estadisticas" exact>
        <GlobalLayout>
          <Guard component={<Estadisticas />} />
        </GlobalLayout>
      </Route>
      <Route path="/estadisticas2" exact>
        <GlobalLayout>
          <Guard component={<Estadisticas2 />} />
        </GlobalLayout>
      </Route>

      <Route path="/kpis" exact>
        <GlobalLayout>
          <Guard component={<Estadisticas2 />} />
        </GlobalLayout>
      </Route>
      <Route path="/tiempos" exact>
        <GlobalLayout>
          <Guard component={<Tiempos />} />
        </GlobalLayout>
      </Route>
      <Route path="/cupscolaborador" exact>
        <GlobalLayout>
          <Guard component={<CUPSColaboradores />} />
        </GlobalLayout>
      </Route>
      <Route path="/traducciones" exact>
        <GlobalLayout>
          <Guard component={<Traducciones />} />
        </GlobalLayout>
      </Route>

      <Route path="/calendario" exact>
        <GlobalLayout>
          <Guard component={<Calendario />} />
        </GlobalLayout>
      </Route>

      <Route path="/timeline" exact>
        <GlobalLayout>
          <Guard component={<Timeline />} />
        </GlobalLayout>
      </Route>

      <Route path="/margenes" exact>
        <GlobalLayout>
          <Guard component={<Margenes />} />
        </GlobalLayout>
      </Route>
      <Route path="/margenes2" exact>
        <GlobalLayout>
          <Guard component={<MargenesCambiados />} />
        </GlobalLayout>
      </Route>
      <Route path="/margenesnew" exact>
        <GlobalLayout>
          <Guard component={<MargenesNew />} />
        </GlobalLayout>
      </Route>

      {/* <Route path="/retencion" exact>
        <GlobalLayout>
          <Guard component={<CalculadoraRetencion />} />
        </GlobalLayout>
      </Route> */}

      <Route path="/leadsPorHora" exact>
        <GlobalLayout>
          <Guard component={<LeadsPorHora />} />
        </GlobalLayout>
      </Route>

      {/* <Route path="/comercializadoras" exact>
        <GlobalLayout>
          <Guard component={<Comercializadoras />} />
        </GlobalLayout>
      </Route> */}

      {/* <Route path="/tarifasComercializadoras" exact>
        <GlobalLayout>
          <Guard component={<TarifasComercializadoras />} />
        </GlobalLayout>
      </Route> */}

      <Route path="/detalleTarifaComercializadora" exact>
        <GlobalLayout>
          <Guard component={<DetalleTarifaComercializadora />} />
        </GlobalLayout>
      </Route>

      <Route path="/desgloseLeads" exact>
        <GlobalLayout>
          <Guard component={<DesgloseLeads />} />
        </GlobalLayout>
      </Route>

      {/* <Route path="/ventajasAhorradas" exact>
        <GlobalLayout>
          <Guard component={<VentajasAhorradas />} />
        </GlobalLayout>
      </Route> */}

      <Route path="/funnelCaidas" exact>
        <GlobalLayout>
          <Guard component={<FunnelCaidas />} />
        </GlobalLayout>
      </Route>

      <Route path="/seguimientoDiarioKpi" exact>
        <GlobalLayout>
          <Guard component={<SeguimientoDiarioKpi />} />
        </GlobalLayout>
      </Route>

      <Route path="/conteobajas" exact>
        <GlobalLayout>
          <Guard component={<ConteoBajas />} />
        </GlobalLayout>
      </Route>

      <Route path="/condicionesTraspaso" exact>
        <GlobalLayout>
          <Guard component={<CondicionesTraspaso />} />
        </GlobalLayout>
      </Route>

      <Route path="/listaRetenciones" exact>
        <GlobalLayout>
          <Guard component={<ListaRetenciones />} />
        </GlobalLayout>
      </Route>

      <Route path="/bloquearLeads" exact>
        <GlobalLayout>
          <Guard component={<BloquearLeads />} />
        </GlobalLayout>
      </Route>

      <Route path="/topsenlinea" exact>
        <GlobalLayout>
          <Guard component={<TopsEnLinea tipo="tops"/>} />
        </GlobalLayout>
      </Route>
      <Route path="/catasenlinea" exact>
        <GlobalLayout>
          <Guard component={<TopsEnLinea tipo="catas" />} />
        </GlobalLayout>
      </Route>
      <Route path="/topshabilitar" exact>
        <GlobalLayout>
          <Guard component={<TopsHabilitar tipo="topsHabilitar" />} />
        </GlobalLayout>
      </Route>

      {/* <Route path="/kpisRepsol" exact>
				<GlobalLayout>
					<Guard component={<KpiRepsol />} />
				</GlobalLayout>
			</Route> */}

      <Route path="/kpisRepsolventas" exact>
        <GlobalLayout>
          <Guard component={<KpiRepsolAg />} />
        </GlobalLayout>
      </Route>

      <Route path="/kpisRepsolBajas" exact>
        <GlobalLayout>
          <Guard component={<KpiRepsolBajasAg />} />
        </GlobalLayout>
      </Route>

      <Route path="/kpisYusep" exact>
        <GlobalLayout>
          <Guard component={<KPIJosep />} />
        </GlobalLayout>
      </Route>

      <Route path="/dashboard" exact>
        <GlobalLayout>
          <Guard component={<Dashboard />} />
        </GlobalLayout>
      </Route>

      <Route path="/reclamaciones_cerradas" exact>
        <GlobalLayout>
          <Guard component={<ReclamacionesCerradas />} />
        </GlobalLayout>
      </Route>
      <Route path="/reclamaciones_pendientes" exact>
        <GlobalLayout>
          <Guard component={<ReclamacionesPendientes />} />
        </GlobalLayout>
      </Route>
      <Route path="/encuestas" exact>
        <GlobalLayout>
          <Guard component={<Encuestas />} />
        </GlobalLayout>
      </Route>

      <Route path="/encuestas_editor" exact>
        <GlobalLayout>
          <Guard component={<NewEncuesta />} />
        </GlobalLayout>
      </Route>

      <Route path="/encuestas_resultados">
        <GlobalLayout>
          <Guard component={<EncuestaResultados />} />
        </GlobalLayout>
      </Route>

      <Route path="/encuestas_resumen">
        <GlobalLayout>
          <Guard component={<ResumenPorUsuario />} />
        </GlobalLayout>
      </Route>

      <Route path="/encuestas_tops">
        <GlobalLayout>
          <Guard component={<VistaTopEncuesta />} />
        </GlobalLayout>
      </Route>

      <Route path="/informe_colaboradores">
        <GlobalLayout>
          <Guard component={<LookerStudio />} />
        </GlobalLayout>
      </Route>
    </Switch>
  );
}