import { EditOutlined, LoadingOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, List, message, notification, Select, Table, Tabs, Tooltip, Tree } from 'antd';
import 'rc-color-picker/assets/index.css';
import React, { useEffect, useRef, useState } from 'react';
import apiRequest from '../../utils/apiRequest';
import FlatCard from "components/FlatCard/FlatCard";
import Transfer from "components/Transfer";
import Void from "atomicComponents/Void";
import dayjs from "dayjs";
import Tarjeta from "components/Card/Tarjeta";
import Entrada from "components/Entrada";

const { TabPane } = Tabs;

const BloquesPriorodad = () => {

    const [filtros, setFiltros] = useState({});

    const [campanas, setCampanas] = useState(null);
    const [campanaSeleccionada, setCampanaSeleccionada] = useState(null);

    const [ultimoCambio, setUltimoCambio] = useState(null);

    const [__bloques, setBloques] = useState(null);
    const [bloquesOriginales, setBloquesOriginales] = useState(null); 
    const [bloqueSeleccionado, setBloqueSeleccionado] = useState(null);
    const [bloquesActualizados, setBloquesActualizados] = useState([]);
    const [showExcepciones, setShowExcepciones] = useState(false);

    const [puedeGuardar, setPuedeGuardar] = useState(true);

    const user = JSON.parse( localStorage.getItem('global') ).user;

    const columns_estados_individuales = [
        {
            "title": "( Peso ) Estado",
            "dataIndex": "nombre",
            "key": "nombre",
            "render": ( nombre, estado ) => `( ${ estado.peso } ) ${ nombre }`
        },
        {
            "title": "Peso extra",
            "dataIndex": "pesoExtra",
            "key": "pesoExtra",
            "render": (pesoExtra, estado) => {
                return <div className="aic">
                    {
                        [ 0, 5, 10, 15].map(( _val, _indx ) => {
                            return <Checkbox
                                key={_indx}
                                checked={ estado.pesoExtra === _val } 
                                onChange={() => { 

                                    //* GUARDAR EN: BLOQUE - EXCEPCIONES - ESTADOS

                                    const _indx_excepcion = bloqueSeleccionado.excepciones.findIndex( _bloque => _bloque._id === campanaSeleccionada._id );
                                    const _indx_estado = campanaSeleccionada.estados.findIndex( _est => _est._id === estado._id );

                                    campanaSeleccionada.estados[_indx_estado].pesoExtra = _val;

                                    bloqueSeleccionado.excepciones[_indx_excepcion] = campanaSeleccionada;

                                    setCampanaSeleccionada({ ...campanaSeleccionada });
                                    actualizarBloque(bloqueSeleccionado);

                                }}
                            > 
                                +{ _val } 
                            </Checkbox>
                        })
                    }
                </div>
            }
        }
    ]
    const columns_estados_bloques = [
        {
            "title": "( Peso ) Estado",
            "dataIndex": "nombre",
            "key": "nombre",
            "render": ( nombre, estado ) => `( ${ estado.peso } ) ${ nombre }`
        },
        {
            "title": "Peso extra",
            "dataIndex": "pesoExtra",
            "key": "pesoExtra",
            "render": (pesoExtra, estado) => {
                
                return <div className="aic">
                    {
                        [ 0, 5, 10, 15 ].map(( _val, _indx ) => {
                            return <Checkbox
                                key={_indx}
                                checked={ estado.pesoExtra === _val } 
                                onChange={() => { 
                                    const _indx_estado = bloqueSeleccionado.estados.findIndex( _est => _est._id === estado._id );
                                    bloqueSeleccionado.estados[_indx_estado].pesoExtra = _val;
                                    setBloqueSeleccionado({ ...bloqueSeleccionado });
                                }}
                            > 
                                +{ _val } 
                            </Checkbox>
                        })
                    }
                </div>
            }
        }
    ]

    const warning_duplicado = ( bloqueOriginal, bloqueDuplicado ) => {

        const duplicados = <div className="mt3">
            {
                bloqueDuplicado.map( _duplicado => {
                    return <span> - Campaña: <span className="bold">{ _duplicado.campana }</span> en la seccion <span className="bold">{ _duplicado.tipo }</span> del bloque <span className="bold">{ _duplicado.bloque }</span> <br/> </span>
                })
            }
        </div>

        return <div className="pt3">
            <div className="bold"> Advertencia duplicados: </div>
            { duplicados }
            <div className="bold mt4"> ¿Quieres continuar? </div>
            <div className="flex jcc m4 pt2"> 
                <Button danger className="mr2" onClick={ () => message.destroy() }> Cancelar </Button>
                <Button type="primary" className="ml2" onClick={ () => { actualizarBloque( bloqueOriginal, true ); message.destroy();} }> Aceptar </Button>
            </div>
        </div>

    }
    const pesoBloque = bloqueSeleccionado ? bloqueSeleccionado.peso : 0;

    // PILLO BLOQUES Y CAMPAÑAS
    useEffect(() => {
    
        (async () => {

            try {

                const getBloques = await apiRequest("get", `/bloquesPrioridad`, null, true);
                const getCampanas = await apiRequest("get", `/campana?sort=${ JSON.stringify( { nombre : 1 } ) }&filter=${ JSON.stringify( {campanaBaja:{$ne:true}, activo: {$ne : false}} ) }`, null, true);
                const getEstados = await apiRequest("get", `/estado?query=${ JSON.stringify( { esFinal : false } ) }`, null, true);
                const getCambios = await apiRequest("get", `/cambiosPendientes`, null, true);

                getBloques.forEach( ( _bloque ) => {
                    _bloque.estados = getEstados.map( ( _estado ) => {
                        const nuevo_estado = _bloque.estados.find( _x => _x._id === _estado._id ); 
                        return nuevo_estado ? nuevo_estado : _estado;
                    })
                });

                const cambiosActuales = getCambios?.length ? getCambios[0] : null;
                const _fecha = cambiosActuales ? cambiosActuales?.cuando?.str : "";
                const _bloquesOriginales = cambiosActuales?.bloquesOriginales ?? structuredClone( getBloques );
                const cambios = compararBloques( _bloquesOriginales, getBloques );
                if ( Object.keys( cambios ).length ) objToTree( cambios, _fecha );

                setBloques(getBloques);
                setBloquesOriginales( _bloquesOriginales );
                setBloqueSeleccionado( getBloques?.length && getBloques[0] );
                if ( getCambios?.length ) setUltimoCambio( getCambios[0] );
                setCampanas(getCampanas);

            } catch (err) {
                console.log(err)
                return message.error("Error al obtener los datos");
            }

        })();
    }, []);

    const objToTree = ( cambiosObj, cambiosActuales ) => {

        //* OBTENEMOS LOS ULTIMOS CAMBIOS DESDE EL HISTORIAL DEL BLOQUE Y LOS MAPEAMOS PARA QUE FUNCIONEN CON EL TREE DE ANTD
        const cambios = {};
        Object.keys(cambiosObj).forEach( ( _key ) => {

            cambiosObj[_key].map( ( _cambio, _indx ) => {

                const resumenCambio = (() => {
                    if ( _cambio.tipo === "AÑADIDA" || _cambio.tipo === "ELIMINADA" ) return <div> Campaña <span className="bold">'{ _cambio.nombre }' { _cambio.tipo }</span> a las <span className="bold">{ _cambio.origen }</span> del bloque <span className="bold">'{ _cambio.bloque }'</span> </div>;
                    if ( _cambio.tipo === "MODIFICADA" && _cambio.tipoCambio === 3 ) return <div> Cambiada priorida del estado <span className="bold"> '{ _cambio.estado }'</span> excepcion <span className="bold"> '{ _cambio.nombre }'</span> de <span className="bold"> '{ _cambio.valorOriginal }'</span> a <span className="bold"> '{ _cambio.valorNuevo }'</span> del bloque <span className="bold"> '{ _cambio.bloque }'</span></div>;   
                    if ( _cambio.tipo === "MODIFICADA" ) return <div> Cambiada priorida del estado <span className="bold"> '{ _cambio.estado }'</span> de <span className="bold"> '{ _cambio.valorOriginal }'</span> a <span className="bold"> '{ _cambio.valorNuevo }'</span> del bloque <span className="bold"> '{ _cambio.bloque }'</span></div>;   
                    return <div> Cambiada prioridad del bloque <span className="bold"> '{ _cambio.bloque }' </span> de <span className="bold"> '{ _cambio.valorOriginal }' </span> a <span className="bold">'{ _cambio.valorNuevo }'</span> </div>;
                })();

                cambios[ _key ] = [ ...( cambios[ _key ] ?? [] ), resumenCambio ];

            });
        })

        const cambiosTree = [
            {
                title: "CAMBIOS",
                key: "0-0-0",
                children: Object.keys( cambios ).map( ( _key, _indx ) => {
                    return {
                        title: _key,
                        key: `0-${ _indx + 1 }-0`,
                        children: cambios[ _key ].map( ( _cambio, __indx ) => { return { title: <div className="flex"> <MinusOutlined  className="mt1 mr2"/> { _cambio } </div>, key: `0-${ _indx + 1 }-${ __indx + 1 }` } } )
                    }
                })
            }
        ]

        const fecha = (() => {
            if ( ultimoCambio ) return ultimoCambio?.cuando?.str;
            if ( cambiosActuales ) return cambiosActuales;
            return "NO ENCONTRADA";
        })();

        console.log( "FECHA: ", fecha );

        if ( cambiosTree[0].children.length ) {
            notification.destroy();
            notification.open({ 
                message: <div className="fs4 bold"> CAMBIOS PENDIENTES EN BLOQUES DE PRIORIDAD </div>,
                description : <div className="maxh40em scroll">
                    <div className="mb3 fs2"> FECHA ULTIMO CAMBIO: <span className="bold"> { fecha } </span> </div>
                    <div>Hay cambios pendientes que se guardarán durante la noche</div>
                    <Void space={1} />
                    <Tree treeData={cambiosTree} selectable={false}/>
                </div>,
                duration: 0,
                placement: 'topRight',
                type: 'warning',
                style: { width: 730, marginTop: 40 }
            });
        } else {
            notification.destroy();
        }
            
    }

    const compararBloques = ( _bloques_1, _bloques_2 ) => {

        if ( !_bloques_1 ) _bloques_1 = bloquesOriginales;
        if ( !_bloques_2 ) _bloques_2 = __bloques;

        /**
         * FUNCION PARA COMPARAR LOS CAMBIOS DEL BLOQUE AL INICIO Y AL FIN DEL FLUJO
         * 
         * @param {Object} bloque1 Bloque original
         * @param {Object} bloque2 Bloque nuevo
         * @returns {Array} Array con todas las campañas con modificaciones
         */
        const compararBloque = ( bloque1, bloque2 ) => {

            const res = {
                cambiosBloque: [],
                campanasAnadidas : [],
                campanasEliminadas : [],
                campanasModificadas : [],
                excepcionesAnadidas : [],
                excepcionesEliminadas : [],
                excepcionesModificadas : []
            };

            // CAMBIO EN PRIORIDADES
            if ( bloque1.peso !== bloque2.peso ) res.cambiosBloque.push( { tipoCambio: 1, tipo: "MODIFICADO", valorOriginal: bloque1.peso, valorNuevo: bloque2.peso, origen: "BLOQUES", bloque: bloque1.nombre } );

            // CAMBIO EN ESTADOS
            bloque1.estados.map( _estado_original => {
                const _estado_nuevo = bloque2.estados.find( _x => _x._id === _estado_original._id );
                if ( _estado_original.pesoExtra !== _estado_nuevo.pesoExtra || _estado_original.peso !== _estado_nuevo.peso ) {
                    res.campanasModificadas.push({ valorOriginal: _estado_original.pesoExtra, valorNuevo: _estado_nuevo.pesoExtra, tipoCambio: 2, tipo: "MODIFICADA", estado: _estado_nuevo.nombre, _id: _estado_nuevo._id, origen: "CAMPAÑAS", bloque: bloque1.nombre });
                }
            });

            // CAMBIO EN CAMPAÑAS
            [ ...bloque2.campanas, ...bloque1.campanas ].forEach( _camp => {
                if ( !bloque1.campanas.find( _x => _x._id === _camp._id ) && bloque2.campanas.find( _x => _x._id === _camp._id ) ) res.campanasAnadidas.push( { ..._camp, tipoCambio: 3, tipo: "AÑADIDA", origen: "CAMPAÑAS", bloque: bloque1.nombre } );
                if ( bloque1.campanas.find( _x => _x._id === _camp._id ) && !bloque2.campanas.find( _x => _x._id === _camp._id ) ) res.campanasEliminadas.push( { ..._camp, tipo: "ELIMINADA", origen: "CAMPAÑAS", bloque: bloque1.nombre } );
            });

            // CAMBIO EN EXCEPCIONES
            const _excepciones = [];
            [ ...bloque2.excepciones, ...bloque1.excepciones ].forEach( _exc => {
                if ( !_excepciones.find( __exc => __exc._id === _exc._id ) ) _excepciones.push( _exc );
            });

            _excepciones.forEach( _camp => {
                const _exc_original = bloque1.excepciones.find( _x => _x._id === _camp._id );
                const _exc_nuevo = bloque2.excepciones.find( _x => _x._id === _camp._id )
                if ( !_exc_original && _exc_nuevo ) res.excepcionesAnadidas.push( { ..._camp, tipoCambio: 3, tipo: "AÑADIDA", origen: "EXCEPCION", bloque: bloque1.nombre } );
                if ( _exc_original && !_exc_nuevo ) res.excepcionesEliminadas.push( { ..._camp, tipo: "ELIMINADA", origen: "EXCEPCION", bloque: bloque1.nombre } );
                if ( _exc_original && _exc_nuevo ) {
                    let buscar = true;
                    _exc_original.estados.map( _estado_original => {
                        const _estado_nuevo = _exc_nuevo.estados.find( _x => _x._id === _estado_original._id );
                        if ( buscar && _estado_original.pesoExtra !== _estado_nuevo.pesoExtra ) {
                            res.excepcionesModificadas.push( { ..._camp, valorOriginal: _estado_original.pesoExtra, valorNuevo: _estado_nuevo.pesoExtra, tipoCambio: 3, estado: _estado_nuevo.nombre, _id: _estado_nuevo._id, tipo: "MODIFICADA", origen: "EXCEPCION", bloque: bloque1.nombre } );
                            buscar = false;
                        }
                    });
                }
            });

            return [ 
                ...res.cambiosBloque, 
                ...res.campanasAnadidas, 
                ...res.campanasEliminadas, 
                ...res.campanasModificadas, 
                ...res.excepcionesAnadidas, 
                ...res.excepcionesEliminadas, 
                ...res.excepcionesModificadas 
            ];

        }

        let campanasActualizar = {};
        _bloques_1.forEach( _bloque_original => {
            const _bloque_nuevo = _bloques_2.find( _x => _x._id === _bloque_original._id );
            const _cambios = compararBloque( _bloque_original, _bloque_nuevo );
            if ( _cambios.length ) campanasActualizar[ _bloque_original.nombre ] = _cambios;
        });

        return campanasActualizar;

    }
    const actualizarBloque = (_bloque, forzar) => {

        const nuevasCampanas = [ ..._bloque.campanas, ..._bloque.excepciones ];

        let duplicado = [];
        const _bloques = __bloques.map( _bloque_nuevo => {

            let _x = { ..._bloque_nuevo };

            if (_x._id !== _bloque._id) {

                //* SI SE COMPARAN LOS DEMÁS BLOQUES SE QUITA EL DUPLICADO DEL OTRO BLOQUE

                nuevasCampanas.forEach( _camp => {
                    if ( _x.campanas.find( __camp => __camp._id === _camp._id ) ) {
                        duplicado = [ ...duplicado, { campana: _camp.nombre, bloque: _x.nombre, tipo: "CAMPAÑAS" } ];
                        _x.campanas = _x.campanas.filter( _campana => _campana._id != _camp._id );
                    }
                    if ( _x.excepciones.find( __camp => __camp._id === _camp._id ) ) {
                        duplicado = [ ...duplicado, { campana: _camp.nombre, bloque: _x.nombre, tipo: "EXCEPCIONES" } ];
                        _x.excepciones = _x.excepciones.filter( _campana => _campana._id != _camp._id );
                    }
                });

            } else {

                _x = _bloque;

                //* SI SE COMPARA EL MISMO BLOQUE SE QUITA EL DUPLICADO DE EXCEPCIONES O CAMPANAS, DEPENDIENDO DE "showExcepciones"

                _x.campanas.forEach( _camp => {
                    if ( _x.excepciones.find( __camp => __camp._id === _camp._id ) ) {
                        if ( showExcepciones ) {
                            duplicado = [ ...duplicado, { campana: _camp.nombre, bloque: _x.nombre, tipo: "CAMPAÑAS" } ];
                            _x.campanas = _x.campanas.filter( _campana => _campana._id != _camp._id );
                        } else {
                            duplicado = [ ...duplicado, { campana: _camp.nombre, bloque: _x.nombre, tipo: "EXCEPCIONES" } ];
                            _x.excepciones = _x.excepciones.filter( _campana => _campana._id != _camp._id );
                        }
                    }
                });

            }

            return _x;

        });

        if ( !forzar && duplicado.length ) return message.warning( warning_duplicado( _bloque, duplicado ), 0 );

        setBloques(_bloques);
        setBloqueSeleccionado(_bloque);

        if (!bloquesActualizados.includes(_bloque._id)) setBloquesActualizados([...bloquesActualizados, bloqueSeleccionado._id])

    }
    const guardarBloques = async () => {

        try {

            const errores = __bloques.filter( _x => _x.peso === "" || isNaN(Number(_x.peso)) ).map( _x => _x.nombre );
            if ( errores.length ) return { ok: false, msg: <div>Los bloques: <span className="bold">{ errores.join(", ") }</span> tienen errores en las prioridades </div> };

            const cambios = compararBloques();
            objToTree( cambios, dayjs().format( 'DD/MM/YYYY HH:mm' ) );

            const bulkBloques = [];
            const campanasFinal = [];
            __bloques.forEach((_bloque) => {

                let _campanas = _bloque.campanas;
                let _excepciones = _bloque.excepciones;

                const cambiosBloque = cambios[_bloque.nombre];

                if ( cambiosBloque && cambiosBloque.length ) {

                    const _campanasFinales = cambiosBloque.filter( _x => [ 1, 2 ].includes( _x.tipoCambio ) ).length 
                        ? [ //* 
                            ..._bloque.excepciones.map( _x => _x._id ),
                            ..._bloque.campanas.map( _x => _x._id )
                        ]
                        : [
                            ...cambiosBloque.filter( _x => _x.tipoCambio === 3 ).map( _x => _x._id )
                        ];

                    campanasFinal.push({
                        bloque: _bloque._id,
                        campanas: _campanasFinales
                    });
    
                    _campanas = _bloque.campanas.map( _campana => {
                        const _campana_obj = campanas.find( _camp => _camp._id === _campana._id );
                        return {
                            _id: _campana_obj._id,
                            nombre: _campana_obj.nombre
                        }
                    });
                    
                    _excepciones = _bloque.excepciones.map( _excepcion => {
                        const _campana_obj = campanas.find( _camp => _camp._id === _excepcion._id );
                        return {
                            _id: _campana_obj._id,
                            nombre: _campana_obj.nombre,
                            estados: _excepcion.estados
                        }
                    });  

                }

                const _historial = [
                    ...( _bloque.historial ? _bloque.historial.splice(-3) : [] ),
                    { 
                        user: {
                            id: user.id, 
                            username: user.username, 
                            name: user.name, 
                            email: user.email, 
                            role: 3, 
                        },
                        fecha: {
                            str: dayjs().format( 'DD/MM/YYYY HH:mm' ),
                            ts: dayjs().unix()
                        },
                        cambios: cambios
                    }
                ]

                bulkBloques.push({
                    updateOne: {
                        filter: { _id: _bloque._id },
                        update: {
                            $set : {
                                peso: _bloque.peso,
                                estados: _bloque.estados, 
                                campanas: _campanas,
                                excepciones: _excepciones,
                                historial: _historial
                            },
                        }
                    }
                })

            });

            //* ACTUALIZAMOS LOS BLOQUES Y LA COLA
            if ( bulkBloques.length ) await apiRequest( 'put', '/bloquesPrioridad', { bulk : bulkBloques }, true );
            if ( campanasFinal.length ) await apiRequest( 'post', '/anadirColaBloquesPrioridad', { campanas : campanasFinal, bloquesOriginales : bloquesOriginales }, true );

            return { ok: true };

        } catch ( err ) {
            console.log( "ERR: ", err );
            return { ok: true, msg: JSON.stringify( err ) };
        }

    }

    const loading = !__bloques || !campanas;
    if (loading) return <div className="w100 jcc fs5 pt5"> <LoadingOutlined /> </div>

    return <div className="flex jcc">

        <div className="verCambios w100 mr20 mt20">
            <Button
                className=""            
                type="danger"
                onClick={() => { 
                    const cambios = compararBloques(); 
                    if  ( Object.keys(cambios).length ) objToTree(cambios) 
                    else message.info("No hay cambios") 
                }}
            > 
                Ver cambios 
            </Button>
        </div>

        <div className="w80">

            

            <div className="w100">

                <div className="flex mt4">
                    <span className="flex w50 fs5 bold mr2"> BLOQUES PRIORIDAD </span>
                </div>

                <Void space={.5} />
                <Void space={1} divider={true} />

                <div className="fww jcc"> 
                    {
                        __bloques.map((_bloque, _indx) => {

                            return <Tarjeta
                                key={_indx}
                                className="w46 m2"
                                titulo={<div className="flex">
                                    <EditOutlined
                                        className="fs3 mr3 aic mb1"
                                        onClick={(event) => {
                                            setBloqueSeleccionado(_bloque)
                                            event.stopPropagation();
                                        }}
                                    />
                                    <p className="fs2"> {_bloque.nombre} <span className="bold"> ( { `${ _bloque.peso > 0 ? "+" : "" }${ _bloque.peso }` } ) </span></p>
                                </div>}
                                children={
                                    <div className="p4 maxh20em">
                                        <Tabs defaultActiveKey="1" centered>
                                            <TabPane tab="CAMPAÑAS" key={`CAM_${_indx}`}>
                                                <Entrada 
                                                    type="text"
                                                    value={ filtros[`${_bloque.nombre}`]?.searchCampana }
                                                    setValue={(search) => setFiltros({ ...filtros, [`${_bloque.nombre}`]: { ...filtros[`${_bloque.nombre}`], searchCampana: search }}) }
                                                />
                                                <List
                                                    className="demo-loadmore-list h10em ofys"
                                                    itemLayout="horizontal"
                                                    dataSource={ filtros[`${_bloque.nombre}`]?.searchCampana 
                                                        ? _bloque.campanas.filter( _x => ( _x.nombreInterno ? _x.nombreInterno : _x.nombre).toUpperCase().includes( filtros[`${_bloque.nombre}`]?.searchCampana?.toUpperCase() ) ) 
                                                        : _bloque.campanas
                                                    }
                                                    renderItem={(item) => <p className="m2"> {item?.nombreInterno ? item.nombreInterno : item.nombre} </p>}
                                                />
                                            </TabPane>
                                            <TabPane tab="EXCEPCIONES" key={`EXC_${_indx}`}>
                                            <Entrada 
                                                    type="text"
                                                    value={ filtros[`${_bloque.nombre}`]?.searchExcepcion }
                                                    setValue={(search) => setFiltros({ ...filtros, [`${_bloque.nombre}`]: { ...filtros[`${_bloque.nombre}`], searchExcepcion: search }}) }
                                                />
                                                <List
                                                    className="demo-loadmore-list h10em ofys"
                                                    itemLayout="horizontal"
                                                    dataSource={ filtros[`${_bloque.nombre}`]?.searchExcepcion 
                                                        ? _bloque.excepciones.filter( _x => _x.nombre.toUpperCase().includes( filtros[`${_bloque.nombre}`]?.searchExcepcion?.toUpperCase() ) ) 
                                                        : _bloque.excepciones
                                                    }
                                                    renderItem={(item) => <p className="m2"> {item.nombre} </p>}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                }
                                collapse
                                collapseDefaultOpen
                            />
                        })
                    }
                </div>

            </div>

            <Void space={3} divider/>
            
            {
                bloqueSeleccionado && <div>

                    <div className="flex jcc aic">
                        <div className="fs6"> BLOQUE ACTUAL: <span className="ml3 bold"> { bloqueSeleccionado.nombre } </span> </div>
                        <div className="abs w85 jcfe">
                            <div className="w30">
                                <span className="w35 fs2 bold mr1"> PRIORIDAD BLOQUE:  </span>
                                <Input 
                                    className="w20 fs2" 
                                    disabled={!bloqueSeleccionado} 
                                    value={pesoBloque > 0 ? `+${ pesoBloque }` : pesoBloque } 
                                    onChange={(ev) => {
                                        let value = ev.target.value;
                                        if ( ![ "+", "-", "" ].includes(value) && isNaN( Number( value ) ) ) value = bloqueSeleccionado.peso;
                                        bloqueSeleccionado && actualizarBloque({ ...bloqueSeleccionado, peso: [ "+", "-", "" ].includes(value) ? value : parseInt( value ) })
                                    }} 
                                />
                            </div>
                        </div>
                    </div>

                    <Void space={3} divider/>

                    <div
                        className="fs4 jcc"
                    >
                        <div className="w40 minw35em p2 bRad5px jcc aic">
                            <span className="w30 fs3 bold"> PRIORIDAD BASE:  </span>
                            <span className="w15 fs3"> { bloqueSeleccionado && bloqueSeleccionado.pesoBase } </span>
                        </div>
                    </div>

                    <FlatCard
                        collapsable
                        title={<p className="fs5 bold colVerCor">CAMPAÑAS</p>}
                    >
                        <Tabs defaultActiveKey="0" centered size="large" onChange={( ev ) => setShowExcepciones( Boolean(Number(ev)) ) } tabBarStyle={{ fontWeight: 'bolder' }}>
                            <TabPane tab="CAMPAÑAS" key={0}>
                                <Transfer
                                    key={2}
                                    dataSource={campanas ?? []}
                                    valueKey="_id"
                                    render={item => item.nombreInterno ? item.nombreInterno : item.nombre}
                                    rowKey={item => item._id}
                                    value={bloqueSeleccionado ? bloqueSeleccionado.campanas.map( _cam => _cam._id) : []}
                                    setValue={(transfered) => {
                                        const _bloque = { ...bloqueSeleccionado };
                                        const _campanas = transfered.map( _camp => {
                                            const original = campanas.find( _x => _x._id === _camp );
                                            return {
                                                _id : original._id,
                                                nombre: original.nombre,
                                                nombreInterno: original.nombreInterno
                                            }
                                        });
                                        _bloque.campanas = _campanas;
                                        actualizarBloque(_bloque);
                                    }}
                                    onSearch={( column, value ) => campanas.filter(_x => (_x.nombreInterno ? _x.nombreInterno : _x.nombre).includes(value)) }
                                    listStyle={{
                                        width: "50%",
                                        height: 400,
                                    }}
                                />
                            </TabPane>
                            <TabPane tab={ <Tooltip placement="top" title={"CAMPAÑAS A LAS QUE NO SE LE APLICARÁN LAS REGLAS DEL BLOQUE EN LOS ESTADOS"}> EXCEPCIONES </Tooltip> } key={1}>
                                <Transfer
                                    key={3}
                                    dataSource={campanas ?? []}
                                    valueKey="_id"
                                    render={item => item.nombre}
                                    rowKey={item => item._id}
                                    value={bloqueSeleccionado ? bloqueSeleccionado.excepciones.map( _cam => _cam._id) : []}
                                    setValue={(transfered) => {
                                        const _bloque = { ...bloqueSeleccionado };
                                        const _excepciones = transfered.map( _camp => {
                                            const original = campanas.find( _x => _x._id === _camp );
                                            const estados_originales = original.estados.map( _estado => {
                                                return {
                                                    _id : _estado._id,
                                                    nombre : _estado.nombre,
                                                    peso : _estado.peso || 0,
                                                    pesoExtra : _estado.pesoExtra || 0,
                                                    esFinal : _estado.esFinal || false
                                                }
                                            });
                                            const existeYa = bloqueSeleccionado.excepciones.find( _x => _x._id === _camp );
                                            return {
                                                _id : original._id,
                                                nombre: original.nombre,
                                                estados: existeYa?.estados ?? estados_originales
                                            }
                                        });
                                        _bloque.excepciones = _excepciones;
                                        if (bloqueSeleccionado) actualizarBloque(_bloque);
                                    }}
                                    listStyle={{
                                        width: "50%",
                                        height: 400,
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </FlatCard>

                    <Void space={1}/>

                    <FlatCard
                        collapsable
                        title={<p className="fs5 bold colVerCor">ESTADOS</p>}
                    >
                        <Tabs defaultActiveKey="1" centered size="large">
                            <TabPane tab="ESTADOS" key={6} className="flex">
                                <div className="w50 p2">
                                    <span className="fs4 bold flex">
                                        <div className="mr3 bold jcc aic mt1"> INDIVIDUAL - CAMPAÑA: </div>
                                        <Tooltip placement="top" title={"AQUI SE EDITARÁN LOS ESTADOS DE LAS EXCEPCIONES"}>
                                            <Select
                                                onChange={val => {

                                                    if ( !val ) return setCampanaSeleccionada({});
                                                    const campanaSeleccionada = bloqueSeleccionado.excepciones.find(_camp => _camp.nombre === val);
                                                    setCampanaSeleccionada(campanaSeleccionada);

                                                }}
                                                options={[
                                                    { label: "", value: "" },
                                                    ...bloqueSeleccionado.excepciones.map(_campana => ({
                                                        label: _campana.nombre,
                                                        value: _campana.nombre,
                                                    }))
                                                ]}
                                                value={ campanaSeleccionada?.nombre ?? null }
                                                className="w50"
                                                showSearch
                                            />
                                        </Tooltip>
                                    </span>
                                    <Void space={1} divider />
                                    <Table
                                        pagination={{ pageSize: 5 }}
                                        dataSource={campanaSeleccionada ? campanaSeleccionada.estados?.filter( _est => !_est.esFinal ) : []}
                                        columns={columns_estados_individuales}
                                    />
                                </div>
                                <div className="w50 p2">
                                    <div className="fs4 mr3 bold mt1"> BLOQUE </div>
                                    <Void space={1} divider />
                                    <Table
                                        rowSelection={true}
                                        pagination={{ pageSize: 5 }}
                                        dataSource={bloqueSeleccionado.estados.filter( _est => !_est.esFinal )}
                                        columns={columns_estados_bloques}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </FlatCard>
                </div>
            }

            <Void space={3} />


            <Button
                size="large"
                type="primary"
                className="w100 mt4 mb5"
                disabled={!bloqueSeleccionado || !puedeGuardar }
                onClick={async () => {

                    message.info("Guardando los datos...");
                    // setBloqueSeleccionado(null);
                    setPuedeGuardar( false );

                    const { ok, msg } = await guardarBloques();
                    
                    setPuedeGuardar( true );

                    if ( !ok ) return message.error( msg );
                    message.success("Cambios guardados!");

                }}
            >
                Guardar
            </Button>

            <Void space={3} />

        </div>

    </div>
};

export default BloquesPriorodad;